import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonLoading from "./ButtonLoading";
import { fas } from "@fortawesome/free-solid-svg-icons";

export const ButtonStatus = ({
  onClick,
  isActive,
  className = "",
}) => {
  const buttonColor = isActive ? "bg-green-500" : "bg-red-500";
  return (
    <button
      className={`${buttonColor} text-white px-2 py-1 rounded-md w-20 ${className}`}
      onClick={onClick}
    >
      {isActive ? "Active" : "Inactive"}
    </button>
  );
};

export const ButtonChecked = ({
  onClick,
  isChecked,
  className = "",
}) => {
  const buttonColor = isChecked ? "bg-green-500" : "bg-red-500";
  return (
    <button
      className={`${buttonColor} text-white px-2 py-1 rounded-md w-28 ${className}`}
      onClick={onClick}
    >
      {isChecked ? "Đã duyệt" : "Chưa duyệt"}
    </button>
  );
};

export const ButtonEdit = ({ children, onClick, className = "", nameBtn }) => {
  return (
    <button
      className={`bg-blue-500 text-white px-2 py-1 rounded-md ${className}`}
      onClick={onClick}
    >
      Sửa
    </button>
  );
};

export const ButtonCreate = ({ children, onClick, className = "" }) => {
  return (
    <button
      className={`bg-green-500 text-white px-2 py-1 rounded-md ${className}`}
      onClick={onClick}
    >
      Tạo mới
    </button>
  );
};

export const ButtonSubmit = ({ children, onClick, className = "" }) => {
  return (
    <button
      className={`bg-red-500 text-white px-2 py-1 rounded-md ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const CloseButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="text-gray-500 hover:text-gray-700 focus:outline-none absolute right-5 top-5"
      aria-label="Close"
    >
      <FontAwesomeIcon icon={["far", "circle-xmark"]} />
    </button>
  );
};

export const Dropdown = ({
  options = [],
  fieldName = "",
  labelName = "Select Option",
  defaultValue = "",
  className = "",
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    fieldName === "is_active" ? true : defaultValue
  );

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    } else if (options.length > 0) {
      setSelectedValue(options[0]?.value || "");
    }
  }, []);

  const handleChange = (e) => {
    const value =
      e.target.value === "true"
        ? true
        : e.target.value === "false"
          ? false
          : e.target.value;
    setSelectedValue(value);
    if (onChange) {
      onChange({ target: { name: fieldName, value } });
    }
  };

  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 mb-2 first-letter:uppercase"
        htmlFor={fieldName}
      >
        {labelName}
      </label>
      <select
        name={fieldName}
        value={selectedValue || defaultValue}
        onChange={handleChange}
        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${className}`}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

// Text Component
export const TextInput = ({
  children,
  labelName = "Field Name",
  fieldName = "",
  defaultValue = "",
  className = "",
  onChange,
  is_required = false,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 mb-2" htmlFor="account_name">
        {labelName}
      </label>
      <input
        type="text"
        name={fieldName}
        defaultValue={defaultValue}
        onChange={onChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        required={is_required}
      />
    </div>
  );
};

export const PasswordInput = ({
  children,
  labelName = "Field Name",
  fieldName = "",
  defaultValue = "",
  className = "",
  onChange,
  is_required = false
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }
  return (
    <div className="mb-4">
      <label className="block text-gray-700">{labelName}</label>
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          name={fieldName}
          defaultValue={defaultValue}
          onChange={onChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required={is_required}
        />
        <button
          type="button"
          onClick={() => togglePasswordVisibility()}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-gray-600"
        >
          {showPassword ? "Hide" : "Show"}
        </button>
      </div>
    </div>
  );
};

// Text Component
export const EmailInput = ({
  children,
  labelName = "Field Name",
  fieldName = "",
  defaultValue = "",
  className = "",
  onChange,
  is_required = false,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 mb-2" htmlFor="account_name">
        {labelName}
      </label>
      <input
        type="email"
        name={fieldName}
        defaultValue={defaultValue}
        onChange={onChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        required={is_required}
      />
    </div>
  );
};
