import React, { useEffect, useState } from "react";
import axiosClient from "../../api/axiosClient";
import { Dropdown, TextInput } from "../../components/UIComponents"; // Assuming these components are reusable
import { DEFAULT_IMG2 } from "../../utils/constants";
import AvatarUploader from "../../UploadFile/AvatarUploader";
import { useToast } from "../../hooks/useToast";
import Loader from "../../components/Loader";

const EditTeamForm = ({ team, open, groups, onClose, onTeamUpdated }) => {
  console.log("🚀 ~ EditTeamForm ~ team:", team)
  const showToast = useToast();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    team_name: team?.team_name || "",
    slogan: team?.slogan || "",
    group_id: team?.group_id?._id || "",
    avatar: team?.avatar || DEFAULT_IMG2,
  });

  useEffect(() => {
    if (team) {
      setFormData({
        team_name: team?.team_name || "",
        slogan: team?.slogan || "",
        group_id: team?.group_id?._id || "",
        avatar: team?.avatar || DEFAULT_IMG2,
      })
    }
  }, []);

  console.log("🚀 ~ EditTeamForm ~ team:", team);

  const [errors, setErrors] = useState({
    team_name: "",
    slogan: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Immediate validation to check for duplicate team names or slogans
    if (name === "team_name") {
      const teamNameExists = groups.some((group) =>
        group.teams?.some(
          (existingTeam) =>
            existingTeam.team_name === value && existingTeam._id !== team._id
        )
      );
      setErrors({
        ...errors,
        team_name: teamNameExists
          ? "Team Name already exists. Please choose another."
          : "",
      });
    }

    if (name === "slogan") {
      const sloganExists = groups.some((group) =>
        group.teams?.some(
          (existingTeam) =>
            existingTeam.slogan === value && existingTeam._id !== team._id
        )
      );
      setErrors({
        ...errors,
        slogan: sloganExists
          ? "Slogan already exists. Please choose another."
          : "",
      });
    }
  };

  const handleAvatarUpload = (avatarUrl) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      avatar: avatarUrl,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Final validation before submission
    if (errors.team_name || errors.slogan) {
      return;
    }

    try {
      setLoading(true);
      const updatedTeam = {
        team_name: formData.team_name,
        slogan: formData.slogan,
        group_id: formData.group_id,
        avatar: formData.avatar,
      };

      const response = await axiosClient.put(`/team/${team._id}`, updatedTeam);
      onTeamUpdated(response);
      showToast("Team updated successfully!");
      setLoading(false);
      onClose();
    } catch (error) {
      console.error(
        "Error updating team:",
        error.response?.data || error.message
      );
      showToast(error.response?.data || error.message, "error");
      setLoading(false);
    }
  };

  return (
    <>
      {open && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white w-full max-w-2xl mx-auto rounded-lg shadow-lg p-6 z-50">
            <h2 className="text-2xl font-semibold mb-4">Chỉnh Sửa Tổ</h2>
            <div className="space-y-4">
              <TextInput
                fieldName="team_name"
                labelName="Tên Tổ"
                defaultValue={formData.team_name}
                onChange={handleChange}
                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring ${errors.team_name ? "border-red-500" : "border-gray-300"
                  }`}
                required
              />
              <p className="text-red-500 text-sm">{errors.team_name}</p>

              <TextInput
                fieldName="slogan"
                labelName="Khẩu Hiệu"
                defaultValue={formData.slogan}
                onChange={handleChange}
                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring ${errors.slogan ? "border-red-500" : "border-gray-300"
                  }`}
              />
              <p className="text-red-500 text-sm">{errors.slogan}</p>

              <Dropdown
                options={groups.map((group) => ({
                  value: group._id,
                  label: group.group_name,
                }))}
                fieldName="group_id"
                labelName="Chọn Khu"
                defaultValue={formData.group_id}
                onChange={handleChange}
                required
              />

              <AvatarUploader
                onUpload={handleAvatarUpload}
                defaultAvatar={team?.avatar}
              />
            </div>

            <div className="mt-6 flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Đóng
              </button>
              <button
                onClick={handleSubmit}
                type="submit"
                disabled={!!errors.team_name || !!errors.slogan}
                className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${!!errors.team_name || !!errors.slogan
                  ? "opacity-50 cursor-not-allowed"
                  : ""
                  }`}
              >
                Cập nhật
              </button>
            </div>
          </div>
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={onClose}
            style={{ pointerEvents: "auto" }}
          ></div>
          {loading && <Loader />}
        </div>
      )}
    </>
  );
};

export default EditTeamForm;
