import React, { useState, createContext, useContext } from 'react';
import { FaTimes } from 'react-icons/fa';

const ToastContext = createContext();

export const useToast = () => {
    return useContext(ToastContext);
};

// Toast provider that manages the toast state
export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const showToast = (message, type = 'success', duration = 10000) => {
        const newToast = { id: Date.now(), message, type, visible: true };

        setToasts((prevToasts) => {
            if (prevToasts.length >= 5) {
                prevToasts.shift();
            }
            return [...prevToasts, newToast];
        });

        setTimeout(() => {
            setToasts((prevToasts) => prevToasts.filter((t) => t.id !== newToast.id));
        }, duration);
    };

    const removeToast = (id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    const getToastStyles = (type) => {
        switch (type) {
            case 'success':
                return 'bg-green-500 text-white';
            case 'error':
                return 'bg-red-500 text-white';
            case 'warning':
                return 'bg-orange-400 text-white';
            default:
                return 'bg-gray-800 text-white';
        }
    };

    return (
        <ToastContext.Provider value={showToast}>
            {children}
            <div className="fixed bottom-1 right-1 flex flex-col space-y-2" style={{ zIndex: 9999 }}>
                {toasts.map((toast) => (
                    <div
                        key={toast.id}
                        className={`transition-transform transform ${getToastStyles(toast.type)} px-6 py-4 rounded-lg shadow-2xl flex items-center space-x-4 ease-in-out ${toast.visible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
                            }`}
                    >
                        <span className="font-semibold text-lg">{toast.message}</span>
                        <button onClick={() => removeToast(toast.id)} className="text-2xl">
                            <FaTimes />
                        </button>
                    </div>
                ))}
            </div>
        </ToastContext.Provider>
    );
};
