import React, { useState } from "react";
import axiosClient from "../../api/axiosClient";
import AvatarUploader from "../../UploadFile/AvatarUploader";
import Loader from "../../components/Loader";
import { useToast } from '../../hooks/useToast';

const CreateGroupDialog = ({ open, onClose, onCreate, existingGroups }) => {
  const showToast = useToast();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    subsidiary: "shbet",
    group_name: "",
    slogan: "",
    avatar: "", // Avatar URL sẽ được set bởi AvatarUploader
  });

  const [errors, setErrors] = useState({
    group_name: "",
    slogan: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "group_name") {
      const groupNameExists = existingGroups?.some(
        (group) => group.group_name === value
      );
      setErrors({
        ...errors,
        group_name: groupNameExists
          ? "Group Name đã tồn tại. Vui lòng chọn tên khác."
          : "",
      });
    }

    if (name === "slogan") {
      const sloganExists = existingGroups?.some(
        (group) => group.slogan === value
      );
      setErrors({
        ...errors,
        slogan: sloganExists
          ? "Slogan đã tồn tại. Vui lòng chọn slogan khác."
          : "",
      });
    }
  };

  const handleAvatarUpload = (avatarUrl) => {
    setFormData({
      ...formData,
      avatar: avatarUrl,
    });
  };

  const handleCreateSubmit = async () => {
    const isDuplicate = existingGroups?.some(
      (group) =>
        group.group_name === formData.group_name &&
        group.slogan === formData.slogan
    );

    if (isDuplicate || errors.group_name || errors.slogan) {
      setErrors({
        group_name: isDuplicate
          ? "Group Name đã tồn tại. Vui lòng chọn tên khác."
          : errors.group_name,
        slogan: isDuplicate
          ? "Slogan đã tồn tại. Vui lòng chọn slogan khác."
          : errors.slogan,
      });
      return;
    }

    try {
      setLoading(true);
      const response = await axiosClient.post("/group", formData);
      onCreate(response);
      showToast("Tạo nhóm thành công!", 'success');
      setLoading(false);
    } catch (error) {
      console.error(
        "Error creating group:",
        error.response?.data || error.message
      );
      showToast(error.response?.data || error.message, 'error');
      setLoading(false);
    }
  };

  return (
    <>
      {open && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white w-full max-w-2xl mx-auto rounded-lg shadow-lg p-6 z-50">
            <h2 className="text-2xl font-semibold mb-4">Tạo mới Khu</h2>
            <div className="space-y-4">
              <input
                type="text"
                name="subsidiary"
                value={formData.subsidiary}
                readOnly
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring border-gray-300 bg-gray-100"
              />
              <input
                type="text"
                name="group_name"
                placeholder="Group Name"
                value={formData.group_name}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring ${errors.group_name ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              <p className="text-red-500 text-sm">{errors.group_name}</p>
              <input
                type="text"
                name="slogan"
                placeholder="Slogan"
                value={formData.slogan}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring ${errors.slogan ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              <p className="text-red-500 text-sm">{errors.slogan}</p>

              <AvatarUploader onUpload={handleAvatarUpload} />
            </div>
            <div className="mt-6 flex justify-end space-x-2">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateSubmit}
                disabled={!!errors.group_name || !!errors.slogan}
                className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${!!errors.group_name || !!errors.slogan ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
              >
                Create
              </button>
            </div>
          </div>
          <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={onClose}></div>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default CreateGroupDialog;
