import React, { useState, useEffect } from 'react';
import axiosClient from '../../api/axiosClient';
import Layout from '../../layouts/Layout';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { ButtonChecked, ButtonEdit, ButtonStatus } from '../../components/UIComponents';
import CreateUserDialog from './CreateUserDialog';
import EditUserDialog from './EditUserDialog';
import { LabelRole } from '../../components/Label';
import { DEFAULT_IMG } from '../../utils/constants';
import usePagination from '../../hooks/usePagination';
import useFilter from '../../hooks/useFilter';
import useSort from '../../hooks/useSort';
import DynamicTable from '../../components/DynamicTable';
import FilterSortUI from '../../components/FilterByRow';
import { getUniqueOptions } from '../../utils/utils';
import { useToast } from "../../hooks/useToast";

const UserList = () => {
    const showToast = useToast();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);

    const navigate = useNavigate();
    const userAuth = JSON.parse(sessionStorage.getItem('user'));
    // Custom hooks for filtering and sorting
    const { filteredItems: filteredUsers, setFilter } = useFilter(users, { group_id: '', team_id: '', full_name: '' });
    const { sortedItems: sortedUsers, sort } = useSort(filteredUsers, { key: 'full_name', direction: 'asc' });
    const {
        currentPage,
        totalPages,
        currentItems: paginatedUsers,
        goToNextPage,
        goToPreviousPage,
        setPage,
    } = usePagination(sortedUsers, 15);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await axiosClient.get('/user');
            setUsers(response);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
            showToast(error.response?.message || error.message, 'error');
            setLoading(false);
        }
    };

    const handleEditOpen = (user) => {
        setSelectedUser(user);
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setSelectedUser(null);
    };

    const handleCreateOpen = () => {
        setCreateOpen(true);
    };

    const handleResetChecked = async () => {
        try {
            setLoading(true);
            await axiosClient.post(`/user/reset-checked`);
            await fetchUsers();
            showToast('Đã reset toàn bộ nhân viên thành chưa duyệt');
        } catch (error) {
            console.error("Error updating account:", error);
            showToast(error.response?.message || error.message, 'error');
        } finally {
            setLoading(false);
        }
    }

    const handleCreateClose = () => {
        setCreateOpen(false);
    };

    const handleUserUpdate = (user_id, updatedData) => {
        const updatedUsers = users.map((user) =>
            user._id === user_id ? { ...user, ...updatedData } : user
        );
        setUsers(updatedUsers);
    };

    const handleUserCreate = (newUser) => {
        setUsers([...users, newUser]);
    };

    const toggleUserStatus = async (user) => {
        try {
            setLoading(true);
            await axiosClient.patch(`/user/${user._id}`, { is_active: !user.is_active });
            await fetchUsers();
            showToast('Cập nhật trạng thái nhân viên thành công');
        } catch (error) {
            console.error("Error updating account:", error);
            showToast(error.response?.message || error.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const toggleUserChecked = async (user) => {
        try {
            setLoading(true);
            const response = await axiosClient.patch(`/user/mark-as-checked`, { user_id: user._id });
            await fetchUsers();
            showToast(`Cập nhật thành công trạng thái ${response.is_checked ? 'đã duyệt' : 'chưa duyệt'}`);
        } catch (error) {
            console.error("Error updating account:", error);
            showToast(error.response?.message || error.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleViewClick = (user_id) => {
        navigate(`/accounts/${user_id}`);
    };

    const filterConfigs = [
        {
            label: 'Full Name',
            key: 'full_name',
            type: 'input',
        },
        {
            label: 'Groups',
            key: 'group_id',
            type: 'select',
            options: getUniqueOptions(users, 'group_id'),
        },
        {
            label: 'Teams',
            key: 'team_id',
            type: 'select',
            options: getUniqueOptions(users, 'team_id'),
        },
    ];

    const userColumn = [
        {
            key: 'avatar',
            label: 'Avatar',
            render: (row) => (
                <img className="w-10 h-10 rounded-full mx-auto" src={row.avatar || DEFAULT_IMG} alt="Avatar" />
            ),
            sortable: false,
        },
        { key: 'full_name', label: 'Họ Tên', render: (row) => row?.full_name || '--', sortable: true },
        { key: 'group_id.group_name', label: 'Khu', render: (row) => row.group_id?.group_name || '--', sortable: false },
        { key: 'team_id.team_name', label: 'Tổ', render: (row) => row.team_id?.team_name || '--', sortable: false },
        {
            key: 'role',
            label: 'Chức Vụ',
            render: (row) => <LabelRole role={row.role || '--'} />,
            sortable: true,
        },
        {
            key: 'is_active',
            label: 'Trạng Thái',
            render: (row) => (
                <ButtonStatus isActive={row.is_active} onClick={() => toggleUserStatus(row)} />
            ),
            sortable: true,
        },
        {
            key: 'is_checked',
            label: 'Kiểm tra',
            render: (row) => (
                <ButtonChecked isChecked={row.is_checked} onClick={() => toggleUserChecked(row)} />
            ),
            sortable: true,
        },
        { key: 'note', label: 'Ghi Chú', render: (row) => row.note || '--', sortable: false },
        {
            key: 'actions',
            label: 'Hoạt Động',
            render: (row) => (
                <div className="flex space-x-2">
                    <ButtonEdit onClick={() => handleEditOpen(row)} nameBtn="NV" />
                    <button className="bg-gray-500 text-white px-2 py-1 rounded mx-2" onClick={() => handleViewClick(row._id)}>
                        DS Tài Khoản
                    </button>
                </div>
            ),
            sortable: false,
        },
    ];

    return (
        <Layout>
            <div className="mx-auto p-4">
                <h2 className="text-2xl font-bold">Danh sách nhân viên</h2>
                {userAuth.role !== 'member' && <div className="flex justify-end gap-2 mb-4">

                    <FilterSortUI
                        filters={filterConfigs}
                        onFilterChange={setFilter}
                        onSortChange={sort}
                    />
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={handleCreateOpen}
                    >
                        Thêm nhân viên
                    </button>
                    {(userAuth.role === 'm_admin' || userAuth.role === 's_admin') && (
                        <button
                            className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-700"
                            onClick={handleResetChecked}
                        >
                            Reset duyệt
                        </button>
                    )}
                </div>}
                <DynamicTable
                    columns={userColumn}
                    data={paginatedUsers}
                    sort={sort}
                    pagination={{
                        currentPage,
                        totalPages,
                        goToNextPage,
                        goToPreviousPage,
                        setPage,
                    }}
                />
                <CreateUserDialog
                    open={createOpen}
                    onClose={handleCreateClose}
                    onCreate={handleUserCreate}
                />
                {selectedUser && (
                    <EditUserDialog
                        open={editOpen}
                        onClose={handleEditClose}
                        user={selectedUser}
                        onUpdate={handleUserUpdate}
                    />
                )}
            </div>
            {loading && <Loader />}
        </Layout>
    );
};

export default UserList;
