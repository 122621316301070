import React, { useState, useEffect } from "react";
import axiosClient from "../../api/axiosClient";
import Layout from "../../layouts/Layout";
import Loader from '../../components/Loader';
import CreateGroupDialog from "./CreateGroupDialog";
import EditGroupDialog from "./EditGroupDialog";
import DynamicTable from '../../components/DynamicTable';
import { ButtonEdit } from '../../components/UIComponents';
import usePagination from '../../hooks/usePagination';
import useFilter from '../../hooks/useFilter';
import useSort from '../../hooks/useSort';
import FilterSortUI from '../../components/FilterByRow';
import { getUniqueOptions } from '../../utils/utils';
import DeleteConfirmationDialog from "../TeamManagement/DeleteConfirmationDialog";
import { useToast } from "../../hooks/useToast";

const GroupList = () => {
  const showToast = useToast();
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  // Custom hooks for filtering and sorting
  const { filteredItems: filteredGroups, setFilter } = useFilter(groups, { subsidiary: '' });
  const { sortedItems: sortedGroups, sort } = useSort(filteredGroups, { key: 'group_name', direction: 'asc' });
  const {
    currentPage,
    totalPages,
    currentItems: paginatedGroups,
    goToNextPage,
    goToPreviousPage,
    setPage,
  } = usePagination(sortedGroups, 10);

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get("/group");
      setGroups(response);
    } catch (error) {
      console.error("Error fetching groups:", error);
      showToast(error.message, 'error')
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteOpen = (group) => {
    setSelectedGroup(group);
    setConfirmDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setSelectedGroup(null);
  };

  const handleDelete = async () => {
    if (selectedGroup) {
      try {
        setLoading(true);
        await axiosClient.delete(`/group/${selectedGroup._id}`);
        setGroups(groups.filter((group) => group._id !== selectedGroup._id));
        showToast('Xóa Tổ Thành Công');
        setLoading(false);
      } catch (error) {
        console.error("Error deleting group:", error);
        showToast(error.message, 'error');
        setLoading(false);
      }
      setConfirmDeleteOpen(false);
    }
  };

  const handleEditOpen = (group) => {
    setSelectedGroup(group);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setSelectedGroup(null);
  };

  const handleCreateOpen = () => {
    setCreateOpen(true);
  };

  const handleCreateClose = () => {
    setCreateOpen(false);
  };

  const handleGroupUpdate = (groupId, updatedData) => {
    const updatedGroups = groups.map((group) =>
      group._id === groupId ? { ...group, ...updatedData } : group
    );
    setGroups(updatedGroups);
    setEditOpen(false);
  };

  const handleGroupCreate = (newGroup) => {
    setGroups([...groups, newGroup]);
    setCreateOpen(false);
  };

  const groupColumns = [
    { key: 'subsidiary', label: 'Subsidiary', render: (row) => row.subsidiary || '--', sortable: true },
    { key: 'group_name', label: 'Tên Khu', render: (row) => row.group_name || '--', sortable: true },
    {
      key: 'avatar',
      label: 'Avatar',
      render: (row) => (
        <img className="w-10 h-10 rounded-full" src={row.avatar || '--'} alt="Avatar" />
      ),
      sortable: false,
    },
    { key: 'slogan', label: 'Khẩu Hiệu', render: (row) => row.slogan || '--', sortable: false },
    {
      key: 'createdAt',
      label: 'Ngày Tạo',
      render: (row) => new Date(row.createdAt).toLocaleDateString() || '--',
      sortable: true,
    },
    {
      key: 'updatedAt',
      label: 'Ngày Sửa',
      render: (row) => new Date(row.updatedAt).toLocaleDateString() || '--',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Hoạt Động',
      render: (row) => (
        <div className="flex space-x-2">
          <ButtonEdit onClick={() => handleEditOpen(row)} nameBtn="Khu" />
          <button
            onClick={() => handleDeleteOpen(row._id)}
            className="bg-red-500 text-white px-2 py-1 rounded mx-2"
          >
            Xóa Khu
          </button>
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <Layout>
      <div className="mx-auto p-4">
        <h2 className="text-2xl font-bold">Quản Lý Khu SHBET</h2>
        <div className="flex justify-end gap-2 mb-4">
          <FilterSortUI
            filters={[{ label: 'Subsidiary', key: 'subsidiary', options: getUniqueOptions(groups, 'subsidiary') }]}
            onFilterChange={setFilter}
            onSortChange={sort}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={handleCreateOpen}
          >
            Tạo Khu Mới
          </button>
        </div>
        <DynamicTable
          columns={groupColumns}
          data={paginatedGroups}
          sort={sort}
          pagination={{
            currentPage,
            totalPages,
            goToNextPage,
            goToPreviousPage,
            setPage,
          }}
        />
        {createOpen && <CreateGroupDialog
          open={createOpen}
          onClose={handleCreateClose}
          onCreate={handleGroupCreate}
        />}
        {editOpen && selectedGroup && (
          <EditGroupDialog
            open={editOpen}
            onClose={handleEditClose}
            group={selectedGroup}
            onUpdate={handleGroupUpdate}
          />
        )}
        {confirmDeleteOpen && selectedGroup && <DeleteConfirmationDialog
          onClose={handleDeleteClose}
          onConfirm={handleDelete}
        />
        }

      </div>
      {loading && <Loader />}
      {confirmDeleteOpen && selectedGroup && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white w-full max-w-md mx-auto rounded-lg shadow-lg p-6 z-50">
            <h2 className="text-2xl font-semibold mb-4">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete the group{" "}
              <strong>{selectedGroup.group_name}</strong>?
            </p>
            <div className="mt-6 flex justify-end space-x-2">
              <button
                onClick={handleDeleteClose}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={handleDeleteClose}
            style={{ pointerEvents: "auto" }}
          ></div>
        </div>
      )}
    </Layout>
  );
};

export default GroupList;
