import React, { useState } from 'react';
import axiosClient from '../../api/axiosClient';
import AuthLayout from '../../layouts/AuthLayout';
import { useNavigate } from 'react-router-dom';
import { PasswordInput, TextInput } from '../../components/UIComponents';
import { useToast } from '../../hooks/useToast';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const showToast = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosClient.post(`/auth/login`, { username, password });
            if (response.accessToken) {
                sessionStorage.setItem('token', response.accessToken);
                sessionStorage.setItem('refresh', response.refreshToken);
                navigate('/');
                showToast('Đăng nhập thành công');
            } else {
                showToast('Đăng nhập thất bại, vui lòng kiểm tra lại');
            }
        } catch (err) {
            showToast('Đăng nhập thất bại, vui lòng kiểm tra lại');
        }
    };

    return (
        <AuthLayout>
            <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
                <h1 className="text-2xl font-bold mb-4">ĐĂNG NHẬP</h1>
                <form onSubmit={handleSubmit}>
                    <TextInput
                        type="text"
                        value={username}
                        labelName='Tên đăng nhập'
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                    />
                    <PasswordInput
                        type="password"
                        value={password}
                        labelName="Mật Khẩu"
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                    />
                    <button
                        type="submit"
                        className="mb-2 w-full bg-blue-500 text-white p-2 rounded"
                    >
                        Đăng Nhập
                    </button>
                </form>
            </div>
        </AuthLayout>
    );
};

export default Login;
