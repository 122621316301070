import { useState, useMemo } from 'react';

/**
 * A custom hook to filter a list of items based on various criteria,
 * including filtering by `key` or `key._id`.
 * @param {Array} items - The list of items to filter.
 * @param {Object} initialFilters - Initial filter values.
 * @returns {Object} - The filtered items and the setFilter function.
 */
const useFilter = (items, initialFilters = {}) => {
    const [filters, setFilters] = useState(initialFilters);

    const filteredItems = useMemo(() => {
        if (!items) return [];

        return items.filter(item => {
            return Object.keys(filters).every(key => {
                const filterValue = filters[key];

                if (!filterValue) return true;

                const itemValue = item[key];

                // Handle full_name with regex for fuzzy search (case-insensitive)
                if (key === 'full_name' && filterValue) {
                    const regex = new RegExp(filterValue, 'i'); // 'i' makes it case-insensitive
                    return regex.test(item.full_name);
                }

                if (itemValue && typeof itemValue === 'object' && '_id' in itemValue) {
                    return String(itemValue._id) === String(filters[key]);
                }

                return String(itemValue) === String(filters[key]);
            });
        });
    }, [items, filters]);

    /**
     * Updates the filter criteria for a specific key.
     * @param {string} key - The key of the filter (e.g., 'group_id', 'team_id').
     * @param {any} value - The value to filter by.
     */
    const setFilter = (key, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [key]: value,
        }));
    };

    return {
        filteredItems,
        setFilter,
    };
};

export default useFilter;