import React, { useState, useEffect } from 'react';
import axiosClient from '../../api/axiosClient';
import { LabelRole } from '../../components/Label';
import { ButtonStatus, PasswordInput } from '../../components/UIComponents';
import Layout from '../../layouts/Layout';
import Loader from '../../components/Loader';
import { useToast } from '../../hooks/useToast';
import { useNavigate } from 'react-router-dom';


const Profile = () => {
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false); // Trạng thái mở modal
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');

    const showToast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axiosClient.get('/auth/fetch');
                setUser(response);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const redirectToLogin = async () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refresh');
        sessionStorage.removeItem('user');

        navigate('/login');
    }

    const handleChangePassword = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setPassword('');
        setRePassword('');
    };

    const handleSubmitPassword = async () => {
        if (!password || !rePassword) {
            showToast('Vui lòng nhập password trước', 'warning');
            return;
        }

        if (password !== rePassword) {
            showToast('Mật khẩu không khớp.', 'warning');
            return;
        }

        try {
            const response = await axiosClient.put('/auth/change-password', { password });
            showToast('Cập nhật mật khẩu thành công.');
            closeModal();
            redirectToLogin();
        } catch (error) {
            showToast('Đã xảy ra lỗi khi cập nhật mật khẩu.', 'error');
        }
    };

    if (loading) {
        return <Loader />
    }
    return (
        <Layout>
            <div className='flex-col rounded-xl sm:rounded-lg'>
                <div
                    className="py-8 px-8 mx-auto bg-white  shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                    <img className="block mx-auto h-24 rounded-full sm:mx-0 sm:shrink-0" src={user?.avatar ? user?.avatar : "https://tailwindcss.com/img/erin-lindford.jpg"} alt="Woman's Face" />
                    <div className="text-center space-y-2 sm:text-left">
                        <div className="space-y-0.5">
                            <p className="text-lg text-black font-semibold">
                                {user.full_name}
                            </p>
                        </div>
                        <LabelRole role={user?.role} />
                    </div>
                </div>
                <div className="bg-white shadow overflow-hidden ">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Giới thiệu:
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    Full name:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {user.full_name}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    Trạng Thái:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <ButtonStatus isActive={user.is_active} />
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    Quản lý mật khẩu
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <button className='bg-orange-500 text-white px-2 py-1 rounded-md' onClick={handleChangePassword}>Đổi Mật Khẩu</button>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                {isModalOpen && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                        {/* Overlay */}
                        <div className="fixed inset-0 bg-black bg-opacity-50"></div>

                        {/* Nội dung Modal */}
                        <div className="bg-white rounded-lg shadow-lg z-10 p-6 max-w-md w-full">
                            <h2 className="text-xl font-bold mb-4 text-gray-700">Đổi Mật Khẩu</h2>
                            <div className="mb-4">
                                <PasswordInput
                                    fieldName="password"
                                    labelName="Mật khẩu mới"
                                    value={password}
                                    defaultValue=''
                                    onChange={(e) => setPassword(e.target.value)}
                                    is_required
                                />
                            </div>

                            <div className="mb-4">
                                <PasswordInput
                                    fieldName="rePassword"
                                    labelName="Nhập lại mật khẩu"
                                    value={rePassword}
                                    onChange={(e) => setRePassword(e.target.value)}
                                    is_required
                                />
                            </div>

                            <div className="flex justify-end space-x-2">
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    onClick={handleSubmitPassword}
                                >
                                    Cập nhật mật khẩu
                                </button>
                                <button
                                    className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                                    onClick={closeModal}
                                >
                                    Hủy
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </div>

        </Layout>
    );
};

export default Profile;
