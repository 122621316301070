import React, { useState, useEffect } from 'react';
import axiosClient from '../../api/axiosClient';
import { useToast } from "../../hooks/useToast";
import CreateTeamForm from './CreateTeamForm';
import EditTeamForm from './EditTeamForm';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import Layout from '../../layouts/Layout';
import Loader from '../../components/Loader';
import DynamicTable from '../../components/DynamicTable';
import { ButtonEdit } from '../../components/UIComponents';
import usePagination from '../../hooks/usePagination';
import useFilter from '../../hooks/useFilter';
import useSort from '../../hooks/useSort';
import FilterSortUI from '../../components/FilterByRow';
import { getUniqueOptions } from '../../utils/utils';
import { DEFAULT_IMG } from '../../utils/constants';

const TeamList = () => {
    const showToast = useToast();
    const [teams, setTeams] = useState([]);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [teamToDelete, setTeamToDelete] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);

    // Custom hooks for filtering and sorting
    const { filteredItems: filteredTeams, setFilter } = useFilter(teams, { group_id: '' });
    const { sortedItems: sortedTeams, sort } = useSort(filteredTeams, { key: 'team_name', direction: 'asc' });
    const {
        currentPage,
        totalPages,
        currentItems: paginatedTeams,
        goToNextPage,
        goToPreviousPage,
        setPage,
    } = usePagination(sortedTeams, 10);

    useEffect(() => {
        const fetchTeamsAndGroups = async () => {
            try {
                setLoading(true);
                const [teamsResponse, groupsResponse] = await Promise.all([
                    axiosClient.get('/team'),
                    axiosClient.get('/group')
                ]);

                const groupsMap = groupsResponse.reduce((acc, group) => {
                    acc[group._id] = group?.group_name;
                    return acc;
                }, {});

                const teamsWithGroupNames = teamsResponse.map(team => ({
                    ...team,
                    group_name: groupsMap[team.group_id] || 'N/A'
                }));

                setTeams(teamsWithGroupNames);
                setGroups(groupsMap);
            } catch (error) {
                console.error('Error fetching teams or groups:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTeamsAndGroups();
    }, []);

    const handleEditOpen = (team) => {
        setSelectedTeam(team);
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setSelectedTeam(null);
    };

    const handleCreateNewTeam = () => {
        setIsCreating(true);
    };

    const handleCloseForm = () => {
        setIsCreating(false);
    };

    const handleTeamCreated = (newTeam) => {
        const group_name = groups[newTeam.group_id] || 'N/A';
        setTeams([...teams, { ...newTeam, group_name }]);
        setIsCreating(false);
    };

    const handleTeamUpdated = (updatedTeam) => {
        setTeams(teams.map(team => (team._id === updatedTeam._id ? { ...updatedTeam, group_name: groups[updatedTeam.group_id] || 'N/A' } : team)));
        setEditOpen(false);
    };

    const handleDeleteTeam = async () => {
        try {
            setLoading(true);
            await axiosClient.delete(`/team/${teamToDelete._id}`);
            setTeams(teams.filter((team) => team._id !== teamToDelete._id));
            showToast('Team deleted successfully!');
        } catch (error) {
            console.error('Error deleting team:', error);
            showToast('Failed to delete team. Please try again.', 'error');
        } finally {
            setTeamToDelete(null);
            setLoading(false);

        }
    };

    const handleDeleteOpen = (team) => {
        setTeamToDelete(team);
        setConfirmDeleteOpen(true)
    };

    const handleDeleteClose = () => {
        setConfirmDeleteOpen(false);
        setSelectedTeam(null);
    };

    if (loading) {
        return <Loader />
    }

    const teamColumns = [
        { key: 'group_name', label: 'KHU', render: (row) => row?.group_id?.group_name || '--', sortable: true },
        { key: 'avatar', label: 'Team Logo', render: (row) => <img src={row?.avatar || DEFAULT_IMG} alt='Team Avatar' className="w-10 h-10 rounded-full" />, sortable: false },
        { key: 'team_name', label: 'Tên của tổ', render: (row) => row?.team_name || '--', sortable: true },
        { key: 'slogan', label: 'Khẩu hiệu', render: (row) => row?.slogan || '--', sortable: false },
        {
            key: 'actions',
            label: 'Hoạt Động',
            render: (row) => (
                <div className="flex space-x-2">
                    <ButtonEdit onClick={() => handleEditOpen(row)} nameBtn="Tổ" />
                    <button
                        className="bg-red-500 text-white px-2 py-1 rounded"
                        onClick={() => handleDeleteOpen(row)}
                    >
                        Xóa Tổ
                    </button>
                </div>
            ),
            sortable: false,
        },
    ];

    return (
        <Layout>
            <div className="mx-auto p-4">
                <h2 className="text-2xl font-bold mb-4">Quản Lý Tổ SHBET</h2>
                <div className="flex justify-end gap-2 mb-4">
                    <FilterSortUI
                        filters={[{ label: 'KHU', key: 'group_id', options: getUniqueOptions(teams, 'group_id') }]}
                        onFilterChange={setFilter}
                        onSortChange={sort}
                    />
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={() => handleCreateNewTeam()}
                    >
                        Tạo Tổ Mới
                    </button>
                </div>
                <DynamicTable
                    columns={teamColumns}
                    data={paginatedTeams}
                    sort={sort}
                    pagination={{
                        currentPage,
                        totalPages,
                        goToNextPage,
                        goToPreviousPage,
                        setPage,
                    }}
                />
                {isCreating && <CreateTeamForm
                    open={isCreating}
                    onClose={handleCloseForm}
                    onTeamCreated={handleTeamCreated}
                />}
                {editOpen && selectedTeam && <EditTeamForm
                    open={editOpen}
                    team={selectedTeam}
                    groups={Object.keys(groups).map(key => ({ _id: key, group_name: groups[key] }))}
                    onClose={handleEditClose}
                    onTeamUpdated={handleTeamUpdated}
                />}
                {confirmDeleteOpen && teamToDelete && <DeleteConfirmationDialog
                    onClose={handleDeleteClose}
                    onConfirm={handleDeleteTeam}
                />}
            </div>

        </Layout>
    );
};

export default TeamList;
