import React from 'react';

export const LabelSocial = ({ type }) => {
    let textColor;

    switch (type.toLowerCase()) {
        case 'zalo':
            textColor = 'text-blue-500';
            break;
        case 'telegram':
            textColor = 'text-indigo-500';
            break;
        case 'facebook':
            textColor = 'text-blue-700';
            break;
        default:
            textColor = 'text-gray-500';
            break;
    }

    return (
        <label className={`${textColor} bold px-2 py-1 rounded inline-block w-24 text-center uppercase`}>
            {type}
        </label>
    );
};

export const LabelRole = ({ role }) => {
    let color;
    let roleVn;

    switch (role.toLowerCase()) {
        case 's_admin':
            color = 'bg-red-500';
            roleVn = "Chủ quản"
            break;
        case 'm_admin':
            color = 'bg-yellow-500';
            roleVn = "QL OA";
            break;
        case 'coach':
            color = 'bg-green-600';
            roleVn = "HL Viên"
            break;
        case 't_leader':
            color = 'bg-pink-500';
            roleVn = "Tổ trưởng";
            break;
        case 'member':
            color = 'bg-indigo-500';
            roleVn = "Nhân viên";
            break;
        default:
            color = 'bg-indigo-500';
            roleVn = "Nhân viên";
            break;
    }
    return (
        <button className={`text-white px-2 py-1 rounded ${color} inline-block w-24 text-center first-letter:uppercase cursor-default hover:text-orange-400`}>
            {roleVn}
        </button >
    );
};
