import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../api/axiosClient";

const AvatarUploader = ({ onUpload, defaultAvatar }) => {
  const [uploading, setUploading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null); // State to store uploaded image URL
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (defaultAvatar) {
      setUploadedImageUrl(defaultAvatar);
    }
  }, [])

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axiosClient.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const avatarUrl = response.url;
      setUploadedImageUrl(avatarUrl); // Set the uploaded image URL
      onUpload(avatarUrl); // Callback to parent component with the URL
      setSuccessMessage("Avatar uploaded successfully!");
    } catch (error) {
      console.error("Error uploading avatar:", error);
      setError("Failed to upload avatar. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="avatar-uploader">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />
      <button
        onClick={handleUploadClick}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        disabled={uploading}
      >
        {uploading ? "Uploading..." : "Upload Avatar"}
      </button>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      {successMessage && <p className="text-green-500 text-sm mt-2">{successMessage}</p>}
      {uploadedImageUrl && (
        <div className="mt-4">
          <img src={uploadedImageUrl || 'https://media.okvipmng.com/1726477026283-ngocrong.png'} alt="Uploaded Avatar" className="w-24 h-24 rounded-full" />
        </div>
      )}
    </div>
  );
};

export default AvatarUploader;
