import React, { useState, useEffect } from "react";
import { Dropdown, TextInput } from "../../components/UIComponents";
import axiosClient from "../../api/axiosClient";
import { useToast } from "../../hooks/useToast";
import AvatarUploader from "../../UploadFile/AvatarUploader";
import Loader from "../../components/Loader";

const CreateTeamDialog = ({ open, onClose, onTeamCreated }) => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    team_name: "",
    slogan: "",
    group_id: null,
    avatar: "",
  });

  const showToast = useToast();

  const handleAvatarUpload = (avatarUrl) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      avatar: avatarUrl, // Set avatar URL from uploader
    }));
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        setLoading(true);
        const response = await axiosClient.get("/group");
        setGroups(response);

        setFormData({
          team_name: "",
          slogan: "",
          group_id: response[0]?._id,
          avatar: "",
        })
      } catch (error) {
        console.error("Error fetching groups:", error);
        showToast("Error fetching groups", "error");
      } finally {
        setLoading(false);
      }
    };
    fetchGroups();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleGroupChange = async (e) => {
    const { name, value } = e.target;
    handleChange({ target: { name, value } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.group_id) {
      showToast("Vui lòng nhập thông tin trước khi tạo.", "error");
      return;
    }

    try {
      setLoading(true);
      const response = await axiosClient.post("/team", formData);
      onTeamCreated(response);
      showToast("Tạo Người Dùng Thành công!", "success");
      onClose();
      setLoading(false);
    } catch (error) {
      console.error("Error creating User:", error);
      showToast(error.response?.data || error?.message, "error");
      setLoading(false);
    }
  };

  return (
    open && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white w-full max-w-2xl mx-auto rounded-lg shadow-lg p-6 z-50">
          <h2 className="text-2xl font-semibold mb-4">Thêm mới tổ</h2>
          <div className="flex-col">
            <TextInput
              fieldName="team_name"
              labelName="Tên của tổ"
              value={formData.team_name}
              onChange={handleChange}
            />
            <TextInput
              fieldName="slogan"
              labelName="Slogan"
              value={formData.slogan}
              onChange={handleChange}
            />
            <Dropdown
              options={groups?.map((group) => ({
                value: group?._id,
                label: group?.group_name,
              }))}
              fieldName="group_id"
              labelName="Khu"
              defaultValue={formData?.group_id || ''}
              onChange={handleGroupChange}
            />
            <AvatarUploader onUpload={handleAvatarUpload} />
          </div>

          <div className="mt-6 flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Đóng
            </button>
            <button
              onClick={handleSubmit}
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Tạo mới
            </button>
          </div>
        </div>
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={onClose}
          style={{ pointerEvents: "auto" }}
        ></div>
        {loading && <Loader />}
      </div>
    )
  );
};

export default CreateTeamDialog;
