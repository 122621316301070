import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client'; // Import socket.io client
import { DEFAULT_IMG } from '../../utils/constants';
import { useToast } from "../../hooks/useToast";

const MessageBox = ({ messages, account, selectedDialog, userSelectedId }) => {
    const [currentMessages, setCurrentMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const socketRef = useRef(null);
    const userAuth = JSON.parse(sessionStorage.getItem('user'));
    const showToast = useToast();
    // Cập nhật messages khi prop 'messages' thay đổi (danh sách tin nhắn từ server)
    useEffect(() => {
        if (messages && messages.length > 0) {
            setCurrentMessages(messages);
        }
    }, [messages]);

    // Kết nối WebSocket và lắng nghe tin nhắn mới
    useEffect(() => {
        if ((!account || !selectedDialog) || (userAuth._id !== userSelectedId)) return;

        // Kết nối tới WebSocket server
        socketRef.current = io.connect(process.env.REACT_APP_WS || 'wss://api.okvipmng.com', {
            query: { accountId: account._id } // Gửi account_id khi kết nối
        });

        socketRef.current.emit('init_telegram_client', {
            accountId: account._id
        });

        // Lắng nghe sự kiện 'message_from_telegram_<account_id>' khi có tin nhắn mới
        socketRef.current.on(`message_from_telegram_${account._id}_${selectedDialog.chat_id}`, (data) => {
            console.log(`Received message from Telegram for account ${data.accountId}:`, data.message);

            // Cập nhật tin nhắn mới vào danh sách
            setCurrentMessages((prevMessages) => [
                { text: data.message },
                ...prevMessages,
            ]);
        });

        // Hủy kết nối socket khi component bị unmount
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, [account, selectedDialog]);

    const handleSendMessage = (e) => {
        if (e.key === 'Enter' && newMessage.trim()) {
            e.preventDefault();

            const messageData = {
                accountId: account._id,
                chat: {
                    chatId: selectedDialog.chat_id,
                    isUser: selectedDialog.is_user,
                    username: selectedDialog.username
                },
                message: newMessage
            };

            socketRef.current.emit('message_from_ui', messageData);

            setCurrentMessages((prevMessages) => [
                { text: newMessage, fromSelf: true, send_date: Math.floor(new Date().getTime() / 1000) },
                ...prevMessages,
            ]);

            setNewMessage('');
        }
    };

    return (
        <>
            {currentMessages && currentMessages.length > 0 ? (
                <div className="flex flex-col max-h-screen"
                    onCopy={(e) => {
                        e.preventDefault();
                        showToast("Sao chép nội dung bị chặn.", 'warning');
                    }}
                >
                    <h3 className="text-md font-semibold mb-2">{selectedDialog?.title}</h3>

                    {/* Message Box */}
                    <div className="flex-1 overflow-y-auto flex flex-col-reverse px-2">
                        {currentMessages.map((message, index) => (
                            <div
                                key={index}
                                className={`mb-4 flex ${message.fromSelf ? 'justify-end' : 'justify-start'}`}
                            >
                                {/* Avatar */}
                                {!message.fromSelf && (
                                    <div className="mr-2">
                                        <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center">
                                            <img className='rounded-full' src={`${DEFAULT_IMG}`} alt={`Avatar ${message?.from?.id}`} />
                                        </div>
                                    </div>
                                )}
                                <div className="max-w-md">
                                    {!message.fromSelf && (
                                        <div className="text-xs text-gray-500 mb-1 font-bold">
                                            {message.from?.first_name || 'Unknown'}
                                        </div>
                                    )}
                                    <div className={`p-3 rounded-lg shadow-md ${message.fromSelf ? 'bg-blue-200' : 'bg-gray-200'}`}>
                                        <p className="text-sm">{message.text}</p>
                                        <div className="text-right text-xs text-gray-400 mt-1">
                                            {new Date(message.send_date * 1000).toLocaleTimeString()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Input Field */}
                    {userAuth._id === userSelectedId && (<div className="flex-none p-2">
                        <input
                            type="text"
                            className="w-full border rounded p-2"
                            placeholder="Type a message"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyDown={handleSendMessage}
                        />
                    </div>)}
                </div>
            ) : (
                <p>Chọn cuộc trò chuyện để hiển thị tin nhắn</p>
            )}
        </>
    );
};

export default MessageBox;
