import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/Routes.js';
import './utils/fontAwesome';
import { ToastProvider } from './hooks/useToast.js';

function App() {
  return (
    <Router>
      <ToastProvider >
        <AppRoutes />
      </ToastProvider>
    </Router>
  );
}

export default App;
